:root {
  --dropdown-width: 350px;
  --dropdown-background: white;
  --dropdown-color: black;
}

.Bam-NavBar {
  width: 100%;
  /* padding: 10px 0 0px 10%; */
  /* / display: flex; / */
}

.Bam-NavBar-search-input-Div {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  padding-left: 5%;
  margin-top: 1%;
}
.Bam-NavBar-search-list {
  position: relative;
  width: 75%;
}

.Bam-NavBar-search-input {
  height: 50px;
  width: 100%;
  border-radius: 10px !important;
}
.searchIconright {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #e1e1e1;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  z-index: 9;
}

.Bam-Navbar-UserPrifile {
  font-size: 40px;
  margin: 0 10px 0px 20px;
  color:black
}
.Bam-Navbar-UserPrifile-login {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.Bam-Home-Bell-Div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Bam-Home-Side-Menu {
  display: none;
  /* margin: 0 10px 0px 20px; */
}

.Bam-NavBar-Modal {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  border-radius: 0px !important;
  /* overflow: scroll; */
  height: 100% !important;
}
.Bam-NavBar-Modal .ant-modal-content .ant-modal-close {
  padding: 0;
}
.Bam-NavBar-Modal .ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-react .video-react-control:before, .video-react .video-react-big-play-button:before{
  top: 60% !important;
  height: auto !important;
}
.video-react-has-started .video-react-control-bar {
  height: 100px !important;
  align-items: flex-end;
  background-image: url(../assets/trackbg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent !important;
}
.video-react .video-react-time-control {
  display: flex;
  align-items: self-end;
  padding-bottom: 10px !important;
}
.video-react .video-react-big-play-button:before {
  top: 0 !important;
}
.video-react .video-react-progress-control {
  padding-bottom: 25px !important;
  align-items: self-end !important;
}

.Bam-search-list {
    background: #fff;
    border-radius: 10px;
    max-width: 80%;
    padding: 15px;
    box-shadow: 0px 0px 13px -6px #000;
    margin-top: 4px;
    position: absolute;
    width: 100%;
    z-index: 99;
    max-height: 250px;
    overflow: auto;
}
.Bam-search-list ul li {
  list-style: none;
  padding: 5px 0px;
  font-size: 16px;
}
.Bam-search-list ul li a{
  color: #2b2b2b;
}

.Bam-NavBar-Modal .ant-modal-content {
  top: 0 !important;
  height: 100vh !important;
  /* overflow: scroll; */
  background-color: rgb(238, 238, 238) !important;
}

.Bam-NavBar-Modal-Logo-Div {
  /* / border-bottom: 2px solid rgb(224, 222, 222); / */
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 15px 0 15px 0;
  position: relative;
}
.ant-modal-content .ant-modal-close {
  padding-top: 10px;
}

.Bam-NavBar-Modal-Logo {
  max-width: 200px;
  position: relative;
  width: 100%;
}
.filterClose {
  border: none;
  background: transparent;
}
.Bam-NavBar-filter-Button {
  padding: 0px 10px;
  border: none;
  border-radius: 40px;
  margin-right: 4px;
  background-color: #e7e8fc;
  color: rgb(70, 70, 70);
  line-height: 33px;
}

.Bam-NavBar-FilterOutlined-Button {
  padding: 0px 10px;
  border: none;
  border-radius: 40px;
  margin-right: 4px;
  background-color: #01b8f2;
  color: rgb(255, 255, 255);
}

.Bam-NavBar-FilterOutlined-Button1 {
  padding: 0px 10px;
  border: none;
  border-radius: 40px;
  margin-right: 4px;
  background-color: #01b8f2;
  color: rgb(255, 255, 255);
  display: none;
}
.loader {
  text-align: center;
}
.search-result-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0;
  gap: 20px;
}
.search-result-section .ant-card-body {
  padding: 0;
  display: flex;
}
.search-result-section .ant-card-bordered {
  border: none;
}
.search-result-section img {
  border-radius: 15px;
}
.history_title{
  font-size: 16px;
  font-weight: 600;
}

.image-container {
  position: relative;
  max-width: 200px; /* Adjust this width as needed */
  height: 130px; /* Adjust this height as needed */
}

.favourite-detailss {
  margin-left: 20px;
}

.favourite-detailss h6 {
  font-weight: 700;
}

.favourite-detailss p {
  color: #646464;
}

.favourite-detailss span {
  color: #00b4ef;
  background: rgb(0 180 239 / 20%);
  padding: 5px 5px;
  border-radius: 10px;
  width: 32px;
  height: 28px;
}

.favourite-detailss span svg {
  font-size: 18px;
}

.duration {
  background-color: rgb(101 99 97 / 70%); /* Adjust the color and opacity as needed */
  color: white;
  padding: 2px 6px;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  right: 10px;
}

.duration .badge {
  display: block;
}

.Bam-NavBar-Button-Div {
  display: flex;
  padding-left: 0%;
  max-width: 90%;
  margin: 0 auto;
  
}

.filter_section {
  max-width: 650px;
}
.filter_action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.filter_action h5 {
  margin-bottom: 0;
  display: flex;
  gap: 5px;
  align-items: center;
}
.filter_options span{
  padding: 5px;
  cursor: pointer;
}
.filterSeleted_options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.filterSeleted_options span {
  background-color: #EEEEEE;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
}
.filterSeleted_options .active {
  background-color: #00B4EF;
  color: #fff;
}
.filter_options .active {
  font-weight: bold;
}
.filterBtn {
  background: #00b2ee !important;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  color: #fff;
  min-width: 80px;
  cursor: pointer;
}

.pagination-container .MuiPagination-ul {
  justify-content: center;
  margin-top: 30px;
}
.pagination-container .MuiPaginationItem-page.Mui-selected,
.pagination-container .MuiPaginationItem-page.Mui-selected:hover, 
.pagination-container .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
  background: #01b8f2;
  color: #fff;
}
.notification-dots {
  background:none;
  border: none;
  position: relative;
  top: 20px;
  cursor: pointer;
}
.notification-setting-actions a {
  height: 38px;
  line-height: 38px;
  color: #363636;
  border: 1px solid #e2e2e2;
  padding: 0 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 15px;
}
.notification-setting-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.notification-setting-actions a:hover {
  background-color: #eee;
}
.notification-setting-actions .Bell-Notifications-Icon {
  font-size: 15px;
}
/* Media query */


@media (max-width: 810px) {
  .Bam-Home-Side-Menu {
    display: block;
    /* margin: 0 10px 0px 20px !important; */
  }

  .Bam-NavBar-search-input-Div {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
  }

  /* .Bam-Navbar-UserPrifile {
    display: none;
  } */

  .Bam-NavBar {
    width: 100%;
    padding: 10px 10px 10px 2%;
  }
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 0px auto;
  }
  .Bam-NavBar-Button-Div {
    /* display: flex; */
    padding-left: 1%;
  }

  .Bam-NavBar-search-input-Div {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
  }

  /* .Bam-NavBar-FilterOutlined-Button {
    display: none;
  } */

  .Bam-NavBar-FilterOutlined-Button1 {
    display: block;
  }

  /* .Display-None {
    display: none;
  } */

  .Bam-NavBar-search-input {
    height: 45px;
    border-radius: 10px !important;
  }
}

@media (max-width: 625px) {
  .Bam-NavBar-search-input {
    border-radius: 10px !important;
  }
  .Bam-Home-Bell-Div {
    gap: 10px
  }
  .Bam-NavBar-Button-Div {
    /* display: flex; */
    padding-left: 1%;
    /* overflow-x: scroll; */
  }

  .Bam-NavBar-search-input-Div {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
  }
}

.Bam-Home-Bell {
  font-size: 25px;
  margin: 0 0px;
  align-self: center;
}

/* / Dropdown styles / */
.dropdown {
  position: relative;
}

.dropdown summary {
  list-style: none;
  list-style-type: none;
}

.dropdown>summary::-webkit-details-marker {
  display: none;
}

.dropdown summary:focus {
  outline: none;
}

.dropdown summary:focus a.button {
  border: 2px solid white;
}

.dropdown ul {
  /* overflow-y: auto;
  overflow-x: hidden; */
  position: absolute;
  margin: 20px 0 0 0;
  padding: 5px 0px;
  width: var(--dropdown-width);
  left: 50%;
  margin-left: calc((var(--dropdown-width) / 1.16) * -1);
  box-sizing: border-box;
  z-index: 1000;
  background: var(--dropdown-background);
  border-radius: 20px;
  list-style: none;
  height: 400px;
}
.loadingText{
  font-size: large;
  text-align: center;
}



/* / Dropdown triangle / */
.dropdown ul::before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 86%;
  margin-left: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--dropdown-background) transparent;
}

/* / Close the dropdown with outside clicks / */
.dropdown>summary::before {
  display: none;
}

.dropdown[open]>summary::before {
  content: " ";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

/* internal code*/
.Bell-Notifications-Icon {
  font-size: 20px;
}

.Bell-Notifications {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
}

.Notifications-Clear-btn {
  background-color: #fde0e2;
  font-weight: 700 !important;
  border: none;
  padding: 0 30px;
  color: #f34969;
  border-radius: 70px !important;
}

.Notifications-btn-div {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.Notifications-Main-Div {
  width: 100%;
  height: 76%;
  background-color: #ffffff;
  /* overflow: scroll; */
  overflow-y: scroll;
}
.Notifications-card-Text-div a{
  color: #363636;
}

.Notifications-Card {
  padding: 10px;
  display: flex;
  height: fit-content;
}

/* All Div of Notification*/
.Notifications-card-Text-div {
  font-size: 0.8rem;
  color: #3f3f3f;
  line-height: normal;
  padding: 0px 5px;
}

.Notifications-card-image-div {
  padding: 0 2px;
}

.Notifications-Menu-Icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* End Div of Notification*/
.Notifications-UserPrifile {
  font-size: 25px;
  margin: 5px 0;
  color: #000;
}

.Notifications-card-Text {
  font-weight: bold;
  color: #2b2b2b;
}

.Notifications-card-View-Text {
  font-size: 0.75rem;
  color: rgb(144, 144, 144);
  margin: 0 !important;
}

.Notifications-card-img {
  border-radius: 20px;
  width: 100%;
  height: 60px !important;
}

.Video-Player-Menu-Image {
  cursor: pointer;
  width: 50px;
}

.Notifications-Clear-btn.ant-btn-default:not(:disabled):hover {
  background-color: #fde0e2;
  font-weight: 700 !important;
  border: none;
  padding: 0 30px;
  color: #f34969 !important;
  border-radius: 70px !important;
}

.Notifications-Clear-btn.ant-btn:hover,
.Notifications-Clear-btn.ant-btn:focus,
.Notifications-Clear-btn.ant-btn:active {
  text-decoration: none;
  background: #fde0e2 !important;
}

.main-section-div {
  padding: 20px;
}
@media(max-width: 900px) {
  .search-result-section {
    grid-template-columns: 1fr;
  }
  .search-result-section .ant-card-body {
    display: block;
  }
}
@media (max-width: 400px) {
  :root {
    --dropdown-width: 300px;
  }
  .dropdown ul {
    right: 0;
    max-width: 300px;
    width: max-content;
    left: 30px;
  }
  .Notifications-Clear-btn {
    font-size: 12px;
  }
  .notification-setting-actions a {
    height: auto;
    line-height: inherit;
    text-align: center;
    padding: 3px 10px;
  }
}

@media (max-width: 330px) {
  :root {
    --dropdown-width: 250px;
  }

  .Notifications-UserPrifile {
    font-size: 18px;
  }

  .Notifications-card-Text-div {
    font-size: 0.7rem;
  }

  .Notifications-card-View-Text {
    font-size: 0.65rem;
  }
}