.custom-tabs .ant-tabs-nav-wrap {
  border-radius: 20px;
  color: black;
  /* width: 30px; */
}

.main_headerterm {
  display: flex;
  margin-bottom: -10px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 39px;
  color: #363636;
  margin-left: 6%;
}
.terms_headeruser{
  max-width: 90%;
  margin: 40px auto 30px;
}


.custom-tabs .ant-tabs-tab {
  border-radius: 20px;
  background-color: black;
  width: 30%;
  justify-content: center;

}


.custom-tabs .ant-tabs-nav .ant-tabs-tab {
  max-width: 172px;
  /* Adjust the width as needed */
  height: 36px;
  border-radius: 20px 20px 20px 20px;
  background-color: #f0f0f0;
  border-bottom: none;
  padding: 12px;
  min-width: 150px;
  /* Remove the default underline */
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab:hover {
  background-color: #00c3ff;
  /* Apply a hover color */
  color: white;
  justify-content: center;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #00B4EF;
  color: white;
  /* Set the text color for the active tab */
  justify-content: center;
}

.custom-tabs .ant-tabs-ink-bar {
  display: none;
  /* Hide the default underline */
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

.custom-tabs .ant-tabs-nav::before {
  border-bottom: none;
  /* Remove the underline */
}

.Terms-Margin {
  /* margin: 20px 0 !important; */
  width: 89%;
  padding: 0 10px;
  text-align: left;
  display: block;
  color: #535353;
  line-height: 25px;
  word-spacing: 1px;
  letter-spacing: 1px;
  max-height: 50vh;
  /* Set the maximum height to 70% of the viewport height */
  overflow-y: scroll;
  overflow-x: hidden;
}

.Terms-Margin1 {
  /* margin: 20px 0 !important; */
  padding: 0 10px;
  text-align: left;
  display: block;
  color: #535353;
  line-height: 25px;
  word-spacing: 1px;
  letter-spacing: 1px;
  max-height: 50vh;
  /* Set the maximum height to 70% of the viewport height */
  overflow-y: scroll;
  overflow-x: hidden;
}
.Terms-Margin1 p{
  word-break: break-all;
}

.Terms-Margin::-webkit-scrollbar {
  width: 3px;
}

.Terms-Margin::-webkit-scrollbar-thumb {
  background-color: rgb(39, 207, 207);
  border-radius: 10px;
}

.Terms-Margin1::-webkit-scrollbar {
  width: 3px;
}

.Terms-Margin1::-webkit-scrollbar-thumb {
  background-color: rgb(39, 207, 207);
  border-radius: 10px;
}


@media (max-width: 767px) {
  .main_headerterm {
    margin-left: 14%;
  }
}

@media (max-width: 625px) {
  .main_headerterm {

    margin-left: 2%;
  }
}


@media (max-width: 412px) {
  .custom-tabs .ant-tabs-nav {
    justify-content: space-between;
  }

  .custom-tabs .ant-tabs-tab {
    flex-grow: 1;
    width: auto;
    max-width: 200px;
  }
}
@media (max-width: 360px) {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    gap: 10px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .custom-tabs .ant-tabs-nav .ant-tabs-tab {
    min-width: auto;
  }
}




