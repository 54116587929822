.Bam-Home {
    display: flex;
  }

  .Bam-Home-Side-Menu-Div {
    background-color: rgb(238, 238, 238);
    width: 300px;
    height: 100vh;
    overflow-Y: auto;
  }
  .Bam-Home-Container-Div {
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
  .Bam-Home-Logo-Div {
    /* / border-bottom: 2px solid rgb(224, 222, 222); / */
    display: flex;
    justify-content: center;
    padding: 16px 0 23px 0;
    position: relative;
  }
  .Bam-Home-Side-Menu-border {
    margin: 0 4px;
    height: 1px;
    border: 1px solid rgb(224, 222, 222);
    border-radius: 50px;
  }
  .Bam-Home-Logo {
    width: 65%;
    position: relative;
    height: min-content;
  }
  .Bam-Home-cloud1 {
    margin-top: 9px;
    width: 30%;
    height: min-content;
    height: auto;
    position: absolute;
    left: -9%;
  }
  .Bam-Home-cloud3 {
    margin-top: 0px;
    width: 70px;
    height: min-content;
    height: auto;
    position: absolute;
    right: 10px;
    bottom: 20px;
  }
  .Bam-Home-Profile-Div {
    padding: 15px 0;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
  }
  .Bam-Home-cloud2 {
    margin-right: 5px;
    width: 25%;
    height: min-content;
    height: auto;
    position: absolute;
    right: 0;
  }
  .Bam-Home-UserPrifile {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .Bam-Home-Profile-Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
  }
  .Bam-Home-card-section {
    width: 80%;
    padding: 20px 8px 0px 8px;
    margin-top: 30px;
    border-radius: 10px;
    background: white;
    position: relative;
    text-align: center;
  }
  .Bam-Home-card-Star-icon {
    margin-top: 9px;
    width: 30%;
    height: min-content;
    height: auto;
    position: absolute;
    top: -35%;
    left: 35%;
  }
  .Bam-Home-slider_icon .ant-slider-track {
    background-color: #fadb14 !important;
    height: 5px;
    border-radius: 20px;
    margin: -5px 0;
  }
  .Bam-Home-slider_icon .ant-slider-rail {
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: #828080;
    border-radius: 20px;
    margin: -5px 0;
  }
  .Bam-Home-slider_icon .ant-slider-handle {
    display: none;
  }
  .Bam-Home-Side-Menu-Video-Settings {
    /* padding: 20px 0px 20px 0px; */
    position: relative;
  }
  .Bam-Home-Side-Menu-Video-Settings p {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px !important;
    font-weight: 600;
    font-size: 12px;
    color: #363636;
  }
  .Bam-Home-Side-Sub-Menu .ant-menu-item-group-title {
    height: 1.5715;
    padding: 0px 16px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    line-height: 1.5715;
    transition: all 0.3s;
  }
  .Bam-Home-Side-Sub-Menu {
    font-weight: lighter !important;
    color: #444444 !important;
  }
  .Bam-Home-Side-Sub-Menu.ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover {
    color: #292828 !important;
  }
  
  .ant-menu-item-selected {
    color: #292828 !important;
  }
  .Bam-Home-Side-Menu-Heading.ant-menu-inline .ant-menu-item::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: none !important;
  }
  .Bam-Home-Side-Menu-Heading.ant-menu:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-image: linear-gradient(-180deg, #ffd931, #f5b810);
    border-radius: 50px;
  }
  .Bam-Home-Side-Menu-Heading {
    width: 100%;
    font-size: 1rem !important;
    font-weight: 700 !important;
    color: black !important;
    background-color: rgb(238, 238, 238) !important;
    padding: 0 15px 120px 15px;
  }
  .Bam-Home-Side-Menu-Settings-p {
    margin-top: 30px !important;
  }
  .Bam-sideManuOption {
    color: #000;
  }
  .Bam-sideManuOption .Bam-menuItemOption{
    padding-left: 20px !important;
    height: 48px;
  }
  .Bam-Home-Side-Sub-Menu-Image {
    width: 22px;
    margin-bottom: 4px;
    margin-right: 15px;
  }
  .awardsSection1 .ant-progress-line, .awardsSection1 .ant-progress-outer, .awardsSection1 .ant-progress-bg{
    height:10px !important;
    padding: 0px !important;
  }
  .awardsSection1 .ant-progress-bg {
  background: #FDED26;
}
.awardsSection1 .ant-progress-text {
  display: none;
}
.awardsSection1  .ant-progress-show-info .ant-progress-outer{
margin-right: 0px !important
}
.card-height{
  height: 80px;
}
  
  
  
  @media (max-width: 810px) {
    .Bam-Home-Side-Menu-Div {
      display: none;
    }
  }
  .conatiner_heading-Video-Slider {
    display: flex;
    font-size: 20px;
    align-items: center;
    padding: 20px 0;
  }
  .conatiner_heading-Video-Slider p {
    margin-bottom: 0;
  }
  .Video-conatiner {
    padding: 15px 0px 0px 10px;
  }
  
  .Bam-Home-Video-Slider-Topic-Icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .top-header {
    background: url(../assets/Rectangle.png);
    padding: 10px 0;
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
  }
