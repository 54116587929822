.splashmaindiv {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/Splash Bg.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    padding: 20px;
}

img.BamSplashImg {
    width: 400px;
    height: 200px;
    opacity: 0.9;
}

.splashdiv {
    max-width: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}

.BamInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0.9;
}

.login_Create_Button {
    text-align: center;
    opacity: 0.9;
    margin-top: 6%;
}

p.bamInfoSmall {
    text-align: center;
    margin: 0;
    opacity: 0.9;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

button.loginButton {
    margin-bottom: 12px;
    width: 350px;
    height: 41px;
    border-style: none;
    border-radius: 10px;
    background-image: linear-gradient(180deg, #F9E551 0%, #F0C426 100%) !important;
    font-size: 16px;
    font-weight: 800;
    opacity: 0.9;
    cursor: pointer;
}

button.createAccountButton {
    width: 350px;
    height: 41px;
    border-style: none;
    border-radius: 10px;
    background-color: #00B4EF !important;
    font-size: 16px;
    font-weight: 700;
    opacity: 0.9;
    color: white;
    cursor: pointer;
}

p.bamInfoBig {
    text-align: center;
    margin: 0;
    opacity: 0.9;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 49px;
}

.google_Fb_login_button_and_info {
    max-width: 400px;
    text-align: center;
    padding-top: 23px;
    opacity: 0.9;
}

.google_Fb_login_button {
    width: 400px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

button.googleloginButton {
    width: 48%;
    height: 33px;
    opacity: 0.9;
    border-radius: 7px;
    color: #655a5a;
    font-size: 16px;
    font-weight: 800;
    background-color: rgb(254, 251, 251);
    border: 1px solid rgb(225, 225, 221)
}

p.socialLoginInfo {
    margin: 0;
    opacity: 0.9;
}

button.FBloginButton {
    width: 48%;
    height: 33px;
    border-radius: 7px;
    color: #655a5a;
    opacity: 0.9;
    font-size: 16px;
    font-weight: 800;
    background-color: white;
    border: 1px solid rgb(225, 225, 221)
}

.header__center {
    font-size: 13px;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    margin-bottom: 10px;
    color: rgb(88 91 91);
    padding: 0 30px;
}

.header__center::before,
.header__center::after {
    background-color: rgb(193, 196, 196);
    content: "";
    display: block;
    height: 1px;
}

button.ant-btn.ant-btn-default.Bam-loginwith-btn {
    background-color: white;
    border-style: none;
    border-radius: 10px;
    width: 190px;
    height: 41px;
}

img.Bam-Logoinwith {
    width: 62%;
    /* height: 108%; */
}

.Bam-loginwith-div {
    display: flex;
    justify-content: space-evenly;
    gap: 15px;
}

.skip-button {
    box-sizing: border-box;

    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    background: #FFFFFF;
    border: 1.5px solid #00B4EF;
    border-radius: 28px;
}
.skipbtn {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border: 1.5px solid #00B4EF;
    padding: 10px 20px 10px 10px;
    gap: 8px;
    border-radius: 35px;
    background: #fff;
    height: 56px;
    position: absolute;
    top: 5%;
    right: 2%;
}
.skipbutton {
    border: 1px solid #00B4EF;
    border-radius: 50%;
}
.skiptext {
    font-style: italic;
    color: #646464;
    font-size: 12px;
}
button:hover {
    background: #fff;
    border-radius: 35px;
    border-color: #00B4EF;
}

@media(max-width: 767px) {
    br{
        display: none;
    }
    img.BamSplashImg {
        max-width: 200px;
        height: auto;
    }
    p.bamInfoBig {
        font-size: 20px;
        line-height: 32px;
    }
    .skipbtn {
        padding: 10 10px 10px 10px;
        height: auto;
    }
    .skiptext {
        display: none;
    }
    .BamInfo {
        padding: 20px;
    }
    .login_Create_Button, button.loginButton, button.createAccountButton {
        width: 100%;
    }
    .splashmaindiv {
        background-size: cover;
        height: auto !important;
    }
    .Bam-loginwith-btn {
        height: auto !important;
    }
    .create {
        font-size: 20px;
    }
}