.custom-play-toggle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #000000;
}
.video-react .video-react-video, .video-react.video-react-fluid {
  border-radius: 10px;
  margin-bottom: 15px;
}
.video-react .video-react-poster {
  border-radius: 15px;
}
.video-react .video-react-big-play-button {
  left: 0 !important;
  right: 0;
  margin: 0 auto;
  top: 40% !important;
  background: #fff !important;
  border: none !important;
  color: #00B4EF !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  line-height: 60px !important;
}
.main-section {
  margin-top: 10px;
  padding: 0 20px;
}
.main-video-section {
  padding: 0 15px;
}

.main-section .card {
  width: 100% !important;
}
.right-video-section {
  height: 100vh;
  overflow-y: auto;
  padding: 0 5px;
}
.video_buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.video_buttons button{
  border-radius: 20px;
  color: #646464;
}
.video_buttons button img{
  margin-right: 5px;
}
.favourite_btn {
  border: none;
  background: #eee;
}
.download_btn {
  border: 1px solid #979797;
}

.right-video-section::-webkit-scrollbar {
  width: 7px;
}

.right-video-section::-webkit-scrollbar-thumb {
  background-color: rgb(39, 207, 207);
  border-radius: 10px;
}
.favorite-button-active{
  background:rgba(0, 180, 239, 0.1) !important;
  color:#00B4EF !important;
  border-color: #00B4EF !important;
}
.favorite-button:focus, .ant-btn:focus {
  border-color: rgba(0, 180, 239, 0.1) !important; 
  color: #646464 !important;
}
.favorite-button:hover, .favorite-button-active:hover, .ant-btn-default:not(:disabled):hover{
  color: #00B4EF !important; 
  border-color: #00B4EF !important;
}
.favorite-button-active.ant-btn:focus {
  color: #40a9ff !important;
}
.free-trial-modal.ant-modal {
  width: 480px !important;
  height: 489px !important;
  border-radius: 50px;
  background: #FFF;
  box-shadow: 0px 22px 44px 0px rgba(0, 0, 0, 0.04);
}

.free-trial-modal .ant-modal-content {
  height: 100%;
  border-radius: 50px;
  background: #FFF;
  box-shadow: 0px 22px 44px 0px rgba(0, 0, 0, 0.04);
  padding: 0 58px 48px 58px;
}

.free-trial-modal .ant-modal-content .ant-modal-close {
  border: 3px solid;
  border-radius: 100%;
  padding: 0;
  width: 36px;
  height: 36px;
  position: absolute;
  right: 37px;
  top: 12px;
}

.free-trial-modal .ant-modal-close-x {
  text-align: center;
  width: 36px;
  height: 36px;
}

.free-trial-modal .ant-modal-body {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.free-trial-modal .free-trial-popup {
  text-align: center;
}

.free-trial-modal .free-trial-popup h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
}

.free-trial-modal .free-trial-popup p {
  color: #646464;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 20px;
}

.free-trial-modal button.ant-btn-default {
  display: block;
  width: 85%;
  margin: 0 auto;
}

.free-trial-modal .ant-btn-default span {
  height: 100%;
}

.free-trial-modal button.free-trial-btn {
  display: block;
  width: 85%;
  margin: 0 auto;
  border: none;
  padding: 15px 0px;
  margin-bottom: 12px;
  border-radius: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.3s ease;
}
.free-trial-modal button.free-trial-btn:hover {
  background: linear-gradient(180deg, #F9E551 0%, #F0C426 100%);
  color: white;
  transition: all 0.3s ease;
}
.free-trial-modal .btn-yellow {
  background: linear-gradient(180deg, #F9E551 0%, #F0C426 100%);
}

.free-trial-modal .btn-grae {
  background-color: #eee;color: #646464;
}

.free-trial-modal .free-trial-popup img {
  margin-bottom: 16px;
}

.free-trial-modal .ant-modal-close-icon svg {
  height: 30px;
  margin-right: 4px;
}

@media(max-width: 767px) {
  .right-video-section {
    padding: 15px;
    height: auto;
  }
  .main-section, .main-video-section {
    padding: 0;
  }
  .video-react .video-react-poster , .video-react .video-react-video, .video-react.video-react-fluid{
    border-radius: 0;
  }
  .video_details {
    padding: 0 15px;
  }
}

