.light_text {
    color: #646464;
    font-size: 15px;
}

.light_text1 {
    color: #646464;
    font-size: 13px;
    margin-top: 1%;
}

.light_text2 {
    color: #646464;
    font-size: 13px;
    margin-top: 1%;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins";
  }

.main_header {
    display: flex;
    margin-bottom: -10px;
    margin-top: 2%;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    color: #363636;
}

.subtitle1 {
    font-size: 16px;
    color: black;
}
.setting_main_section {
    padding: 20px;
    max-width: 80%;
}
.subscription_main_section {
    padding: 20px;
    max-width: 80%;
    background: #f1f1f1;
    border-radius: 5px;
}

.subscription-button{
   text-align: center;
}
.subscription-button .Bam-signup-button {
    width: 50% !important;
    margin: 0 auto;
}
.setting_button {
    border-radius: 10px;
    width: 150;
    border: none;
    background-color: #eee;
    color: #646464;
}
.delete_account_section {
    background-color: rgba(236, 27, 36, 0.09);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
}
.delete_account_section button {
    background-color: #EC1B24;
    color: #fff;
    border-radius: 10px;
    width: 150;
    border: none;
}
.rigth_delete_section {
    display: flex;
    align-items: center;
    justify-content: end;
}
.rigth_delete_section a {
    background-color: #01B3EF;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
}
.rigth_delete_section a:hover {
    background-color: #fff;
    color: #01B3EF;
}
.delete_account_section .conatiner_heading-Video-Slider img {
    width: 54px;
    height: 54px;
}
.setting_main_section .ant-select-selector {
    max-width: 170px;
    width: 170px !important;
    border-radius: 10px !important;
    height: 52px !important;
}
.setting_main_section .ant-select-selector .ant-select-selection-item {
    line-height: 50px !important;
}

@media (max-width: 767px) {
    .Bam-NavBar-Button-Div {
      /* display: flex; */
      padding-left: 1%;
      overflow-x: scroll;
      max-width: 100%;
    }
    .setting_main_section, .subscription_main_section, .subscription-button .Bam-signup-button {
        max-width: 100%;
        width: 100% !important;
    }
    .rigth_delete_section {
        display: block;
    }
    .mt-20 {
        margin-top: 20px;
    }
}

@media (max-width: 625px) {
    .Bam-NavBar-Button-Div {
      padding-left: 1%;
    }
}

/* style={{ "}} 
style={{ display: "flex", alignItems: "center", padding: '16px', background: "#ffd9de" ,borderRadius: "0px 10px 10px 0px" }} */