* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.border {
  border: 1px solid red;
}

.Bam-Sign-position {
  position: relative;
}

.Bam-signup-Image-div {
  background-image: url("../assets/Splash Background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 900px;
  position: relative;
}

.Bam-Signup-div {
  background-color: rgb(225, 225, 225);
  padding: 40px 7% 40px 7%;
}

.Bam-Logo {
  width: 150px;
}

.signupMargin {
  margin-bottom: 20px;
}

.signupMargin1 {
  margin-bottom: 10px;
}

.Bam-Form {
  margin-bottom: 0px;
  padding-bottom: 0;
  /* / font-weight: 700; / */
}

.label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */


  color: #363636;
}

.Bam-signup-button {
  width: 100% !important;
  color: #363636 !important;
  background: linear-gradient(180deg, #F9E551 0%, #F0C426 100%) !important;
  border-radius: 10px !important;
  height: 48px !important;
  border: none !important;
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #363636;
}

.Bam-loginwith-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Bam-loginwith-btn {
  width: 49% !important;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 10px !important;
}

/* .Bam-Logoinwith {
  width: 40px;
  padding: 8px;
} */

/* .ant-form-item {
      margin-bottom: 15px;
  } */
.Bam-account {
  font-weight: bold;
  font-size: 1.4rem;
}
.create{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 45px;
/* identical to box height */
color: #363636;
}

.no-worries{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 27px;
/* identical to box height */
color: #363636;
}


.hey-font{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 45px;
/* identical to box height */


color: #363636;
}
.already-a-user {
  text-align: center;
}

.Bam-span-color {
  color: #00B4EF;
  cursor: pointer;
}

.signUp {
  color: #00B4EF;
  cursor: pointer;
  font-weight: 400;
  font-style: bold;
}

.Bam-checkbox {
  font-weight: normal;
  font-size: 0.85rem !important;
  color: #646464;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: rgb(255, 255, 255) !important;
}

.ant-btn:focus {
  color: rgb(0, 0, 0) !important;
  border-color: rgb(39, 207, 207) !important;
  background: #ffffff00;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgb(39, 207, 207) !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #ffffff !important;
  border-color: white !important;
}

.ant-checkbox-checked:after {
  border-color: #ffffff !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-mxhywb).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #ffffff !important;
}
.Bam-Signup-text p{
  margin-bottom: 0;
  font-size: 28px;
  max-width: 80%;
  padding-left: 57px;
  width: 100%;
  position: relative;
  top: -44px;
}
.Bam-Signup-text {
  background: linear-gradient(#00000000,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.086),
      #00000076);
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  padding: 0;
  letter-spacing: 1px;
  line-height: 50px;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: end;
  justify-content: space-around;
}

.cloud1 {
  /* / border: 1px solid red; / */
  width: 100px;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 30%;
}

.cloud2 {
  /* / border: 1px solid red; / */
  width: 100px;
  position: absolute;
  z-index: 10;
  top: 35%;
  right: -50px;
}

.cloud3 {
  /* / border: 1px solid red; / */
  width: 80px;
  position: absolute;
  z-index: 10;
  top: 7%;
  left: 85%;
}

@media (max-height: 800px) {
  /* .Bam-Signup-text {
    font-size: 1.8rem;
    padding: 30px 50px;
  } */
}

@media (max-width: 1080px) {
  .cloud1 {
    top: 100%;
    left: 35%;
  }

  .cloud2 {
    top: 50%;
  }

  .cloud3 {
    left: 85%;
  }

  .Bam-Signup-text {
    font-size: 1.6rem;
    padding: 0;
  }
}

@media (max-width: 874px) {
  .cloud1 {
    top: 100%;
    left: 27%;
  }

  .cloud2 {
    top: 50%;
  }

  .cloud3 {
    left: 80%;
  }

  .Bam-Signup-text {
    font-size: 1.4rem;
    padding: 0;
    line-height: 40px;
  }
}

@media (max-width: 770px) {
  .cloud1 {
    display: none;
  }

  .cloud2 {
    display: none !important;
  }

  .cloud3 {
    display: none;
  }

  .Bam-Signup-text {
    font-size: 1.3rem;
    padding: 0;
    line-height: 40px;
  }
}

.ant-btn-primary:not(:disabled):hover {
  color: #000000 !important;
  background-color: inherit !important;
}

.ant-btn-default:not(:disabled):hover {
  color: #000000 !important;
  border-color: #4096ff00 !important;
}

.ant-input:hover {
  border-color: rgba(245, 245, 220, 0) !important;
  border-inline-end-width: 0px !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ffffff00 !important;
  border-inline-end-width: 0px !important;
  z-index: 0 !important;
}

.ant-input:focus {
  border-color: #4096ff00 !important;
  box-shadow: 0 0 0 0px rgb(5 145 255 / 0%) !important;
  border-inline-end-width: 0 !important;
  outline: 0 !important;
}

.ant-input-affix-wrapper:focus {
  border-color: #4096ff00 !important;
  box-shadow: 0 0 0 0px rgb(5 145 255 / 0%) !important;
  border-inline-end-width: 0 !important;
  outline: none !important;
  z-index: 1 !important;
}

.ant-input-affix-wrapper-focused {
  border-color: #4096ff00 !important;
  box-shadow: 0 0 0 0px rgba(5, 147, 255, 0) !important;
  border-inline-end-width: 0px !important;
  outline: 0 !important;
}

.Terms-Modal div.ant-modal-content {
  height: 400px !important;
  border-radius: 30px !important;
  padding: 30px;
  position: relative;
}
.Terms-Modal .cloud_Dark4 {
  position: absolute;
  left: -33px;
  top: 13%;
}
.Terms-Modal .cloud_Dark5 {
  position: absolute;
  top: 2%;
  right: -47px;
  left: auto;
}

.Terms-Modal .ant-modal-footer {
  display: none;
}

.Terms-Modal .ant-modal-close-x {
  display: none;
}

.Terms-Modal-Div {
  /* / display: flex; / */
  justify-content: center;
  justify-content: space-around;
  text-align: center;
}

.Tearms-Textarea-Margin {
  margin: 20px 0 !important;
  width: 100%;
  height: 200px !important;
  overflow: scroll;
  padding: 0 10px;
  text-align: left;
  display: block;
  color: #535353;
  line-height: 25px;
  word-spacing: 1px;
  letter-spacing: 1px;
}

.Terms-Textarea {
  display: block;
}

.Terms-Textarea2 {
  display: none;
}

.Tearms-Textarea-Margin::-webkit-scrollbar {
  width: 4px;
}

.Tearms-Textarea-Margin::-webkit-scrollbar-thumb {
  background-color: rgb(39, 207, 207);
  border-radius: 10px;
}

.Bam-Modal-Continue-btn {
  background-image: linear-gradient(-180deg, #ffd931, #f5b810) !important;
  width: 45% !important;
  font-weight: 700 !important;
  height: 40px !important;
  border-radius: 10px !important;
}

.Bam-Modal-cancel-btn {
  width: 45% !important;
  font-weight: 700 !important;
  height: 40px !important;
  border-radius: 10px !important;
  background-color: rgb(238, 238, 238) !important;
}

.Terms-Modal-btn {
  width: 45% !important;
  border: none !important;
  border-bottom: 2px solid rgb(196, 196, 196) !important;
}

.Terms-Modal-btn1 {
  width: 45% !important;
  border: none !important;
  border-bottom: 2px solid #363636 !important;
  font-weight: 600;
}

.Terms-Modal-btnN {
  width: 45% !important;
  border: none !important;
  border-bottom: 2px solid #363636 !important;
  font-weight: 600;
  

}

.Terms-Modal-btn.ant-btn:focus {
  border-color: #363636 !important;
}

.Terms-Modal-btn:focus {
  border-color: #363636 !important;
}

.Terms-Modal-btn.ant-btn:hover {
  border-color: rgb(125, 125, 125) !important;
  border-radius: 0;
}

.cloud_Dark4 {
  width: 60px;
  position: fixed;
  left: 30%;
  top: 26%;
}

.cloud_Dark5 {
  width: 80px;
  position: fixed;
  left: 65%;
  top: 20%;
}

.Decline-Modal-Div {
  /* / display: flex; / */
  justify-content: center;
  justify-content: space-around;
  text-align: center;
  margin-top: 30px;
}

.Bam-Modal-tc-btn {
  background-image: linear-gradient(-180deg, #ffd931, #f5b810) !important;
  width: 60% !important;
  font-weight: 700 !important;
  height: 40px !important;
  border-radius: 10px !important;
}

.anxiety {
  width: 80px;
  margin-bottom: 20px;
}

.Decline-Modal-P {
  color: rgb(124, 124, 124);
  /* / letter-spacing: 1px; / */
  word-spacing: 2px;
}