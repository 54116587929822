.Bam-Login-div {
  background-color: rgb(225, 225, 225);
  padding: 18vh 7% 15vh 7%;
}
.Login-height {
  height: 100vh;
}
.Login-Forget-Password {
  cursor: pointer;
  text-align: right;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height */
color: #00B4EF;
}
.Bam-Login-Logo {
  width: 100px;
}

.LoginMargin {
  margin-bottom: 3vh;
}

.welcome{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 27px;
/* identical to box height */
margin-bottom: 3%;

color: #363636;
}
.cloud4 {
  width: 100px;
  position: absolute;
  z-index: 10;
  top: 68%;
  left: 58%;
}
.cloud5 {
  width: 100px;
  position: absolute;
  z-index: 10;
  top: 32%;
  right: -50px;
}
.cloud6 {
  width: 80px;
  position: absolute;
  z-index: 10;
  top: 10%;
  left: 85vw;
}
.cloud7 {
  width: 80px;
  position: absolute;
  z-index: 10;
  top: 2%;
  left: 53vw;
}
.Bam-height-radius {
  height: 48px !important;
  border-radius: 10px !important;
}

.Login-Modal .ant-modal-footer {
  display: none;
}
.Login-Modal .ant-modal-close-x {
  display: none;
}
.Login-Modal div.ant-modal-content {
  /* height: 400px !important; */
  border-radius: 30px !important;
  padding: 80px 20px 30px 20px;
}
.Login-Modal-Div {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.Login-Modal-btn-Div {
  display: flex;
  justify-content: space-around;
  padding: 10px 30px 5px 30px;
  text-align: center;
}
.Login-Modal-Logo {
  width: 70px;
}

.Bam-Modal-Continue-btn {
  background-image: linear-gradient(-180deg, #ffd931, #f5b810);
  width: 45%;
  font-weight: 600;
  height: 45px;
  border-radius: 10px;
  border: none;
  /* line-height: 40px; */
  color: #363636;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Bam-Modal-cancel-btn {
  width: 45%;
  font-weight: 600;
  height: 45px;
  border-radius: 10px;
  background-color: rgb(238, 238, 238);
  border: none;
  /* line-height: 40px; */
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Bam-Wants-To-Signin {
  font-weight: bold;
  font-size: 1.2rem;
  padding: 10px 0px;
  text-align: center;
  word-spacing: 2px;
  letter-spacing: 1px;
}
.Bam-Wants-To-Signin p{
  margin-bottom: 0;
}

.Bam-share-information {
  font-size: 0.9rem;
  padding: 5px 20px;
  color: rgb(106, 106, 106);
  text-align: center;
}
.cloud_Dark1 {
  width: 60px;
  position: absolute;
  left: 8%;
}
.cloud_Dark2 {
  width: 80px;
  position: absolute;
  left: 66%;
  top: 5%;
}
.cloud_Dark3 {
  width: 50px;
  position: absolute;
  left: 75%;
  top: 33%;
}
.ant-modal-body {
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.errorMark{
  height: 14px;
  width: 14px;
}

.ant-form-item-explain-error {
  color: #e0564b;
}
@media (max-width: 860px) {
  .cloud4 {
   display: none;
  }
  .cloud6 {
    top: 10%;
    left: 85%;
  }
  .cloud7 {
    top: 2%;
    left: 53vw;
  }
}
@media (max-width: 750px) {
  .cloud4 {
    display: none;
  }
  .cloud5 {
    display: none;
  }
  .cloud_Dark1 {
    left: 20px;
  }
  .cloud_Dark3 {
    left: auto;
    right: 20px;
  }
  .Bam-Wants-To-Signin {
    font-size: 16px;
  }
  .Login-Modal-btn-Div {
    padding: 0;
  }
}
@media (max-width: 555px) {
  .cloud7 {
    top: 2%;
    left: 1%;
  }
  .cloud6 {
    top: 10%;
    left: 70%;
  }
}