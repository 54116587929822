.Bam-Container {
    background: linear-gradient(to bottom, #c4eeff 6%,#fff 100%);
  }

  .Bam-Container-landinPage {
    background: url(../assets/banner_landingpage.png);
  }
  
  .banner_main_div {
    display: flex;
    width: 100%;
    gap: 50px;
    justify-content: space-between;
    height: 100%;
  }
  .carousel-indicators {
    left: 0 !important;
    margin-left: 0 !important;
    right: auto;
  }
  .homepage_banner {
    padding: 50px 50px 0 50px;
    min-height: 300px;
  }
  .Bam-Container-image-kid_Group-Div {
    text-align: right;
  }
  .Bam-Container-image-kid_Group {
    width: 90%;
    height: 100%;
  }
  
  .Bam-Container-Carousel-Div {
    width: 35%;
  }
  .play-btn-Display {
    display: none;
  }
  
  
  .Bam-Carousel-top-text {
    font-size: 1rem;
  }
  .Bam-Carousel-Main-text {
    font-size: 1.5rem;
    font-weight: bold;
    max-width: 350px;
  }
  .banner_playbtn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  .carousel-item img {
    position: absolute;
    right: 0;
    top: 0;
    width: 350px;
    height: auto;
  }
  @media (max-width: 1000px) {
    .Bam-Container-Carousel-text {
      padding: 0 0 20% 20%;
    }
  
    .Bam-Carousel-top-text {
      font-size: 0.9rem;
    }
    .Bam-Carousel-Main-text {
      font-size: 1.3rem;
      font-weight: bold;
    }
    .play-btn {
      width: 110px;
      height: 110px;
      margin: 50px 20px auto;
    }
  }
  @media (max-width: 900px) {
    .play-btn {
      width: 100px;
      height: 100px;
      margin: 50px 10px auto;
    }
  }
  @media (max-width: 700px) {
    .Bam-Container-image-kid_Group {
      width: 100%;
      height: 80%;
    }
    .Bam-Container-Carousel-text {
      padding: 0 0 25% 10px;
    }
  
    .Bam-Carousel-top-text {
      font-size: 0.8rem;
    }
    .Bam-Carousel-Main-text {
      font-size: 1.2rem;
      font-weight: bold;
      max-width: 45%;
    }
    .carousel-item img {
      right: 10%;
      top: 30%;
      width: 150px;
      height: 150px;
      object-fit: cover;
    }
    .banner_playbtn{
      top: 55%;
      left: 2%;
      right: auto;
    }
    .banner_playbtn img {
      width: 60%;
    }
    .homepage_banner {
      padding: 0;
    }
  }
  @media (max-width: 400px) {
    .Bam-Container-Carousel-text {
      padding: 0 0 25% 5px;
    }
  
    .Bam-Carousel-top-text {
      font-size: 0.7rem;
    }
    .Bam-Carousel-Main-text {
      font-size: 1rem;
      font-weight: bold;
    }
    .Bam-Container-image-kid_Group {
      width: 100%;
      height: 70%;
    }
  }
  
  @media (max-width: 767px) {
    .play-btn-Display {
      display: block;
    }
    .play-btn {
      margin: 0 auto;
    }
  }
  