.downloaded-videos-list {
    display: flex;
    flex-wrap: wrap;
}

.ant-card.ant-card-bordered.video-card {
    flex: 0 0 50%;
}

.video-container video {
    height: 120px;
    width: 100%;
}

.delete-fvrt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.image-container {
    max-width: 280px;
    width: none;
}
.download-page {
    margin-left: 10px;
    width: 50%;
}
.download-page h6 {
    font-weight: 700;
    margin: 0 0 17px;
    word-break: break-all;
}
.download-page p {
    font-size: 18px;
    color: #EC1B24;
    margin: 0;
    background: rgb(236 27 36 / 10%);
    padding: 2px 20px;
    border-radius: 10px;
}
img.delete-video {
    width: 18px;
}

.modal-data .ant-modal-body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.modal-data .ant-modal-body img {
    width: 40px;
    height: 40px;
}

.modal-data .ant-modal-body p {
    margin-bottom: 0;
    margin-left: 21px;
    font-size: 16px;
    line-height: 26px;
    color: #363636;
    flex: 1;
    font-weight: 600;
}
.modal-data .ant-modal-content {
    width: 300px;
    height: 147px;
    border-radius: 10px;
    padding: 18px 28px 33px 19px;
}



.modal-data .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    margin-left: 40px;
    justify-content: flex-end;
    padding-bottom: 0;
}

.modal-data .ant-btn-primary {
    background: yellow;
    border: none;
    margin-right: 8px;
    border-radius: 16px;
    color: #363636;
    font-size: 14px;
    font-weight: 600;
}

.modal-data .ant-btn-default {
    border: none;
    border-radius: 15px;
    background-color: #EEE;
    color: #646464;
    font-size: 14px;
    font-weight: 400;
}

.modal-data .ant-modal-close-x {
    display: none;
}

.search-result-section img {
    border-radius: 0;
    /* margin-right: 10px; */
}

.Notifications-btn-div-offline {
    display: flex;
    justify-content: center;
    padding: 2px 20px;
    border-radius: 20px;
    background: rgb(236 27 36 / 10%);
    align-items: center;
}

.duration {
    position: relative;
}

.search-result-section .ant-card-body {
    align-items: center;
}

.video-container {
    width: 50%;
    position: relative;
    height: 110px;
}

.Notifications-btn-div-offline span {
    color: #EC1B24;
}

@media screen and (max-width: 575px) {
    .video-react-has-started .video-react-control-bar {
        font-size: 8px;
    }
}

@media screen and (max-width: 991px) {
    .Video-Slider-card-img {
        max-height: 300px;
        min-height: 300px;
    }
}