.notification-section {
  display: flex;
  max-width: 90%;
}
.notification-section p{
  margin-bottom: 0;
}
.notification-section span {
  font-size: 14px;
  color: #646464;
}
.notification-section-left {
  border-right: 1px solid rgba(151, 151, 151, 0.3);
}
.notification-option-select {
  padding-left: 35px;
}
.notification-option {
  max-width: 80%;
  border: 1px solid #E9E9E9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 16px;
}
.notification-option.active {
  border: 1px solid #00B4EF;
}
.notification-option p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
.notification-on-option {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.notification-on-option .ant-switch-checked {
  border: 2px solid #00B4EF;
  background: transparent;
}
.ant-switch {
  background-color: transparent;
  border: 2px solid #c1c1c1;
}
.notification-on-option .ant-switch-handle {
  width: 14px;
  height: 14px;
  left: 5px;
}
.notification-on-option .ant-switch-checked .ant-switch-handle:before {
  background-color: #00B4EF;
}
.notification-on-option .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 0px);
}
.notification_text h4 {
  color: #363636;
  font-size: 24px;
  font-weight: 600;
}
.notification_text {
  margin-bottom: 40px;
}
@media (max-width:767px) {
  .notification-section {
    display: block;
    max-width: 100%;
  }
  .notification-section-left {
    border: none;
  }
  .notification-option {
    max-width: 100%;
  }
  .notification-option-select {
    padding-left: 0;
  }
}
