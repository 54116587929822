.card {
  transition: 0.3s;
  padding: 10px;
  border: none !important;
}

.Video-Slider-card-img {
  border-radius: 8px;
  width: 100%;
  height: 100% !important;
  min-height: 180px;
  max-height: 180px;
}

.image-section {
  display: flex;
  align-items: end;
  flex-direction: column;
  position: relative;
  margin-right: 15px;
}


.Video-Slider-container {
  /* padding: 5px 16px; */
}
.Video-Slider-container-Heading-Text {
  font-weight: bold;
  margin-bottom: 0;
  line-height: 21px;
  padding: 10px 0 5px 0;
  min-height: 50px;
  color: #363636;
}

.slick-prev {
  left: 0 !important;
  right: 80px !important;
  margin-left: auto;
}
.video-slider-view-count {
  font-size: 12px;
  color: #646464;
  line-height: 18px;
}
.slick-next {
  right: 30px !important;
}

.slick-next:before {
  font-size: 40px;
  color: #21212136;
}

.slick-prev:before {
  font-size: 40px;
  color: #21212136;
}

.site-badge-count-109 {
  border-radius: 25px;
  padding: 3px 8px;
  background-color: rgb(0 0 0 / 40%);
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  font-size: 9px;
}
.slick-prev,
.slick-next {
  top: -44px !important;
  width: 25px !important;
  height: 25px !important;
  background: none !important;
  /* / border-radius: 50%; / */
}
.slick-prev:before,
.slick-next:before {
  color: rgb(179, 179, 179) !important;
  border-radius: 100%;
}
.paid-member {
  position: absolute;
  top: 10px;
  left: 10px;
}
@media (max-width: 767px) {

  .Video-Slider-card-img {
    min-height: 120px !important;
    height: 100% !important;
  }
}

@media (max-width: 600px) {
  .slick-prev {
    left: 0 !important;
    right: 80px !important;
    margin-left: auto;
  }
  /* .slick-next {
    left: 90% !important;
  } */
  .image-section {
    margin-right: 0;
  }
}
@media (max-width: 466px) {
  /* .card {
    width: 170px !important;
  } */
  /* .slick-prev {
    left: 83% !important;
  } */
  /* .slick-next {
    left: 88% !important;
  } */
}
@media (max-width: 363px) {
  /* .card {
    width: 130px !important;
  } */
  /* .slick-prev {
    left: 72% !important;
  } */
  /* .slick-next {
    left: 80% !important;
  } */
}
@media(min-width: 810px) and (max-width: 915px) {
  /* .card {
    width: 180px !important;
  } */
}
