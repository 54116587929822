.Nailed-It-body {
    background-image: url("../assets/Splash Bg.png") !important;
     background-repeat: no-repeat;
     background-size: 100% 100%;
     height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
   }
   
   .Nailed-It-Div {
     width: 400px;
     height: 350px;
     border-radius: 30px;
     background-color: rgb(255, 255, 255);
     padding: 40px 20px 20px 20px;
     text-align: center;
   }
   
   .cloud_Dark6 {
     width: 60px;
     position: fixed;
     left: 35%;
     top: 35%;
   }
   .cloud_Dark7 {
     width: 80px;
     position: fixed;
     left: 59%;
     top: 27%;
   }
   
   .Nailed-It-btn {
     background-image: linear-gradient(-180deg, #ffd931, #f5b810) !important;
     width: 60% !important;
     font-weight: 700 !important;
     height: 40px !important;
     border-radius: 10px !important;
   }
   .Nailed-It-Logo {
     width: 80px;
     margin-bottom: 20px;
   }
   .Nailed-It-P {
     color: rgb(124, 124, 124);
     word-spacing: 2px;
   }
   .Nailed-It-account {
     font-weight: bold;
     font-size: 1.4rem;
     color: rgb(39, 207, 207);
   }
   .Nailed-It-account-Created {
     font-size: 1.1rem;
   }
   @media (max-width: 400px) {
     .Nailed-It-Div {
       width: 100%;
     }
     .cloud_Dark6 {
       display: none;
     }
     .cloud_Dark7 {
       display: none;
     }
   }
   @media (max-width: 600px) {
     .cloud_Dark6 {
       left: 10%;
       top: 35%;
     }
     .cloud_Dark7 {
       left: 75%;
       top: 27%;
     }
   }
   